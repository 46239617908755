/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { BreadcrumbsContext } from './utils/breadcrumbs/breadcrumbs-context';
import { SurveyContext, SurveyContextType } from './utils/SurveyInProgress/surveyInProgress';
import { PageLayout } from './components';
import './App.scss?v=1.0';
import { logout, selectAuth } from './store/authentication/slice';

// eslint-disable-next-line import/no-named-as-default
import { trialsMenu, allMenu, cohortMenu, privacyMenu, registryMenu, hsgMenu } from './utils/navigation';

// import { ReviewerTasks, SiteSettings, StudySettings, StudyTracker, Studies, ReviewerParticipantTask, SiteStatus, Site, Study, Participant, Recent, StudyDrive, Registries, Audits, Audit, PrivacyDashboard, Hives, Hive, Cohort, SharedCohort, Patients, Logs, Cohorts, Patient, StudyDashboard, SharedCohorts, Registry, PatientCarePathway, RegistryPatients, RegistrySettings, ClinicPatients, CarePathwayTracker, Conferences, Conference, PatientAssessments, PatientScans, ClinicFiles, PatientDashboard, PatientOverview } from './pages/private';
import { selectLan } from './store/intl';
import { Login, VerifyAccount, CreateProfile, FAQ, Help, Home, Landing, Maintenance, Privacy, Study, Survey, Terms, UserProfile, Research, CreateAccount, ResendVerify, ForgotPassword, Discover } from './pages/hsg';


Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    region: 'us-east-1',
  },
  Storage: {
    AWSS3: {
      bucket: 'upstream-dev-files-us-east-1-drive', // REQUIRED -  Amazon S3 bucket
      region: 'us-east-1', // OPTIONAL -  Amazon service region
    },
  },
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);



function AppRoutes() {
  return <Routes>
    <Route path="landing" element={<Landing />} />
    <Route path="privacy" element={<Privacy />} />

    <Route path="terms" element={<Terms />} />
    <Route path="terms/:id" element={<Terms />} />
    <Route path="privacy" element={<Privacy />} />

    {/* <Route path="support"  > */}
    <Route path="help" element={<Help />} />
    <Route path="faq" element={<FAQ />} />
    {/* </Route> */}

    {/* AUTH */}
    <Route path="login" element={<Login />} />
    <Route path="re-verify" element={<ResendVerify />} />
    <Route path="login" element={<Login />} />
    <Route path="verify" element={<VerifyAccount />} />
    <Route path="create-profile" element={<CreateProfile />} />
    <Route path="create-account" element={<CreateAccount />} />
    <Route path="forgot-password" element={<ForgotPassword />} />

    <Route path="research" element={<Research />} />
    <Route path="home" element={<Home />} />
    <Route path="maintenance" element={<Maintenance />} />
    <Route path="survey" element={<Survey />} />
    <Route path="study" element={<Study />} />
    <Route path="profile" element={<UserProfile />} />
    <Route path="discover" element={<Discover />} />

    <Route path="*" element={<Landing />} />
  </Routes>;
}



function App() {

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: string | undefined) => {
    i18n.changeLanguage(lng);
  };
  const { token, groups, user } = useSelector(selectAuth);
  const { code, label } = useSelector(selectLan);
  const [breadcrumbs, setBreadcrumbs] = React.useState<any[]>([]);
  const contextValue = useMemo(() => ({ breadcrumbs, setBreadcrumbs }), [breadcrumbs, setBreadcrumbs]);
  const [surveyInProgress, setSurveyInProgress] = useState<boolean>(false);

  const value: SurveyContextType = {
    surveyInProgress,
    setSurveyInProgress,
  };

  useEffect(() => {
    if (code) {
      changeLanguage(code);
    }
  }, [code]);

  const getMenu = () => {
    switch (process.env.REACT_APP_FEATURE) {
      case 'MASTER':
        return allMenu;
      case 'TRIALS':
        return trialsMenu;
      case 'PRIVACY':
        return privacyMenu;
      case 'COHORT':
        return cohortMenu;
      case 'REGISTRY':
        return registryMenu;
      default:
        return allMenu;
    }
  };

  const memoizedValue = useMemo(() => ({ breadcrumbs, setBreadcrumbs }), [breadcrumbs, setBreadcrumbs]);



  return (
    <SurveyContext.Provider value={value}>
      <BrowserRouter>
        <PageLayout
          token={token}
          logout={logout}
          navigation={hsgMenu}
          userMenuItems={[{
            title: 'Sign out',
            url: '/login',
            private: true,
          }]}
          header
          footer
        >
          <AppRoutes />
        </PageLayout>
      </BrowserRouter>
    </SurveyContext.Provider>
  );
}

export default App;