import React, { useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { FaCalendarAlt } from 'react-icons/fa';

import Label from '../Label/Label';
import './date-picker.scss';

export interface DatePickerProps {
  value: any;
  invalid?: boolean;
  onChange: (date: any) => void;
  onError?: () => void;
  id?: string;
  label: string;
  invalidText?: string;
  helperText?: string;
}

const isMobile = () =>
  window.innerWidth <= 768
  ;

const CustomDatePicker = (props: DatePickerProps) => {
  const { value, onChange, id, label, invalid, invalidText, helperText } = props;
  const [inputVal, setInputVal] = React.useState<Date | null>(null);
  const [pickerOpen, setPickerOpen] = React.useState(false);
  const pickerRef = React.useRef<HTMLInputElement | null>(null);
  const preventFocusOpen = React.useRef(false);

  console.log('pickerRef', pickerRef);

  React.useEffect(() => {
    const date = new Date(value);
    const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    setInputVal(utcDate);
  }, [value]);

  const handleChange = (date: any) => {
    if (onChange) {
      onChange(date);
    }
    if (dayjs(date).isValid()) {
      setInputVal(date);
      setPickerOpen(false); // Close the picker after selecting a date
    } else {
      console.log('Invalid date');
    }
  };

  const handleIconClick = () => {
    // if (pickerRef.current || pickerOpen) {
    //   console.log('1>> pickerOpen!', pickerOpen);
    //   console.log('1>> pickerRef', pickerRef.current);
    //   setPickerOpen(false);
    //   pickerRef.current = null;
    // // eslint-disable-next-line @typescript-eslint/keyword-spacing
    // } else {
    //   console.log('2>> pickerOpen!', pickerOpen);
    //   console.log('2>> pickerRef', pickerRef.current);
    //   setPickerOpen(true);
    // }
    // console.log('Icon clicked');
    setPickerOpen((prev) => !prev);
    preventFocusOpen.current = true;

  };


  const handlePickerClose = () => {
    // console.log('handlePickerClose');
    setPickerOpen(false);
    preventFocusOpen.current = true;
    setTimeout(() => {
      preventFocusOpen.current = false;
    }, 0);
  };

  const handleInputFocus = () => {
    // console.log('handleInputFocus');
    if (isMobile() && !pickerOpen && !preventFocusOpen.current) {
      handleIconClick();
    }
    preventFocusOpen.current = false;

  };

  return (
    <div className="date-picker__outer-wrapper" id={id} >
      {label && (
        <Label className="input__label" size="h5" weight={600} color='primary'>
          {label}
        </Label>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
          open={pickerOpen}
          onClose={handlePickerClose}
          views={['year', 'month', 'day']}
          value={inputVal}
          onChange={handleChange}
          ref={pickerRef}
          className='date-input'

          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              onFocus={handleInputFocus}
              fullWidth
              error={invalid}
              // inputRef={pickerRef}
              className='date-input-field'
              helperText={invalid ? invalidText : helperText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleIconClick}>
                      <FaCalendarAlt />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

CustomDatePicker.defaultProps = {
  helperText: '',
  invalid: false,
  onError: () => console.error('er'),
  id: 'datepicker',
  invalidText: 'Invalid Date',
};


export default CustomDatePicker;
