import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Label, Logo, Spacer, Checkbox, Modal, Alert } from '../../../components/atoms';
import { PhoneInput, TextInput } from '../../../components/molecules';
import { Credentials, selectAuth, confirmSignUp, authenticateUser } from '../../../store/authentication/slice';
import api from '../../../api/api';
import { AlertState } from '../../../types/types';
import { useAddUserToCognitoGroupMutation } from '../../../api/builders/hsg.api';

interface LocationState {
  user: any;
}

const defaultUser = {
  email: '',
  phone: '',
  password: '',
  passwordConfirm: '',
  firstname: '',
  lastname: '',
  agree: false,
  status: '',
  code: '',
};

const VerifyAccount = () => {
  const location = useLocation();
  const { user } = location?.state as LocationState;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [addToGroup, { isLoading, isSuccess }] = useAddUserToCognitoGroupMutation();

  const [state, setState] = useState(defaultUser);

  const { status, error, token } = useSelector(selectAuth);


  const handleAddToGroup = async () => {
    await dispatch(authenticateUser({
      username: user.email,
      password: user.password,
    }));
    const pl = {
      GroupName: 'TESTING',
    };
    addToGroup(pl);
  };

  React.useEffect(() => {
    if (status === 'confirmSignUpSuccess') {
      handleAddToGroup();
      // navigate('/login');
    }
  }, [status]);

  React.useEffect(() => {
    if (token) {
      navigate('/research');
    }

  }, [isSuccess]);

  React.useEffect(() => {
    setState((prev) => ({ ...prev, 'email': user.email }));
  }, [user]);

  const [alert, setAlert] = React.useState<AlertState>({
    open: false,
    message: '',
    kind: 'error',
  });

  const resetAlert = () => {
    setAlert({
      open: false,
      message: '',
      kind: 'error',
    });
  };

  React.useEffect(() => {
    if (error) {
      setAlert({
        open: true,
        message: `Error Creating Account: ${error}`,
        kind: 'error',
      });
      setState((prev) => ({ ...prev, 'status': '' }));
    }
  }, [error]);



  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('e', e.target.value);
    setState((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const onSubmit = async () => {
    setState((prev) => ({ ...prev, 'status': 'loading' }));
    const pl = {
      email: state.email,
      code: state.code,
    };
    await dispatch(confirmSignUp(pl));
  };


  const goToHelp = () => {
    navigate('/help');
  };

  const goToResendVerify = () => {
    navigate('/re-verify');
  };

  return (
    <div className="hsg-login">
      <Spacer value={30} />
      <Logo size='75px' type='small' />
      <Label center size='h1' color='primary'>Verify Account</Label>
      <div className='hsg-login__form-wrapper'>
        <Label size="h5" color="default" > We just sent an email to {user?.email} </Label>
        <Spacer value={10} />
        <Label size="h5" color="default" >
          If you don&apos;t see a message in your inbox, make sure the email address listed above is correct and check your spam or junk mail folder. This email is sent from mail@myhdstory.org.
        </Label>
        <Spacer value={10} />
        <div className="d-flex">
          <Label size="h5" color="default" >
            If you want us to resend the email
          </Label>
          <Spacer value={5} />
          <span onClick={goToResendVerify} role='button' tabIndex={-1} className="hsg-login__txt-btn">
            <Label size="h5" weight={600} color="primary">
              Click Here
            </Label>
          </span>
        </div>
        <Spacer value={10} />
        <div className="d-flex">
          <Label size="h5" color="default" >
            For further questions
          </Label>
          <Spacer value={5} />
          <span onClick={goToHelp} role='button' tabIndex={0} className="hsg-login__txt-btn">
            <Label size="h5" weight={600} color="primary">
              Contact Customer Care
            </Label>
          </span>
        </div>

        <Spacer value={30} />

        <TextInput
          id="email"
          type="Email"
          autocomplete="Email"
          value={state.email}
          placeholder='Email'
          label='Email'
          onChange={onChange}
        />
        <Spacer value={10} />
        <TextInput
          id="code"
          type="code"
          value={state.code}
          placeholder='Code'
          label='Code'
          onChange={onChange}
        />

        <Spacer value={20} />
        <div className='btn-row'>
          <Button loading={state.status === 'loading'} onClick={onSubmit} >Submit</Button>
        </div>
      </div>
      <Alert
        severity={alert.kind}
        open={alert.open}
        onClose={resetAlert}
        message={alert.message}
      />
    </div>
  );
};

export default VerifyAccount;
